import React from "react";
import "./about.css";

const About = () => {
    return (
        <section className="about-section">
            <div className="about-me">
                <h1>aboutMe</h1>
                <p className="paragraphs">
                    I am from Rahim Yar Khan, a city located in South of Punjab,
                    Pakistan. This beautiful city is enriched by the fertile
                    lands nourished by the Indus River on one side, while the
                    scorching Cholistan Desert lies on the other. I have a
                    passion for reading, traveling, and forging new friendships.
                    Over the years, my job experiences have made me a valuable
                    team member and an effective problem solver.
                </p>
            </div>

            <div className="jobs-wrapper">
                <div className="education">
                    <h1>education</h1>
                    <p className="paragraphs">Currently, I am a student of "<span className="bold-text">Software Engineering</span>" from Virtual University of Pakistan.</p>
                </div>

                <div className="job-experiences">
                    <h1>jobExperiences</h1>

                    <p className="paragraphs">
                        1.{" "}
                        <span className="bold-text">
                            Computer Science Teacher
                        </span>{" "}
                        (<span className="color-year">2010-12</span>) : After
                        completing ICS (Intermediate in Computer Science) from
                        Punjab College, RYK., I started teaching in a private
                        school. This helped me a lot increasing teaching and
                        presentation skills. Those days, my favourite qoute was
                        of Albert Einstein's: "If you can't explain it to a
                        six-year-old, you don't understand it yourself."
                    </p>

                    <p className="paragraphs">
                        2.{" "}
                        <span className="bold-text">
                            Airmen, Pakistan Airforce
                        </span>{" "}
                        (<span className="color-year">2013-17</span>) : This is
                        the unforgetable experience of my life. It was tough,
                        rough, and very challenging. It was like learning
                        everything from the raw.
                    </p>

                    <p className="paragraphs">
                        3. <span className="bold-text">Admin Assistant</span> (
                        <span className="color-year">2022-23</span>) : My main
                        duties were to deal with the labourers and It was a
                        worthy experience dealing at very low level.
                    </p>

                    <p className="paragraphs">
                        4.{" "}
                        <span className="bold-text">
                            Email Support Executive
                        </span>{" "}
                        (<span className="color-year">2023-2024</span>) : This
                        corporate-cultured job was the place I practiced all of
                        my life-long skills and got admired from the early
                        months for the punctuality, hard work, quick adoptance
                        to changing work-policies, and many more. I really liked
                        it and still appreciate it very much.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default About;
