import call from "./call.png";
import email from './email.png';
import facebook from './facebook.png';
import github from './github.png';
import instagram from './instagram.png';
import linkedin from './linkedin.png';
import menu from './menu.png';
import whatsapp from './whatsapp.png';


const icons = {
    call,
    email,
    facebook,
    github,
    instagram,
    linkedin,
    menu,
    whatsapp,
};

export default icons;
