import React from 'react';
import { useImperativeHandle, useRef } from "react";
import "./navbar.css";
import icons from "../../assets/icons/Icons";

const Navbar = () => {
   const navRef = useRef();

   const showNavLinks = () => {
      navRef.current.classList.toggle("responsive_nav");
   };

   return (
      <header>
         <h1 className="logo">
            <a href="/">
               <span className="logo-char">B</span>ilal
               <span className="logo-char">A</span>sghar
            </a>
         </h1>

         <nav ref={navRef} className="nav-links">
            <a href="/">aboutMe</a>
            <a href="/">devPortfolio</a>
            <a href="/">contactMe</a>
         </nav>

         <button className="nav-btn" onClick={showNavLinks}>
            <img src={icons.menu} alt="menu button" />
         </button>
      </header>
   );
};

export default Navbar;
