import React from 'react';
import "./portfolio.css";
import working from "../../assets/images/working.png";

const Skills = () => {
    return (
        <section className="portfolio-section">
            <h1 className="section-heading">devPortfolio</h1>

            <div className="projects">
                <div>
                    <img src={working} alt="" />
                    <h2>Working!</h2>
                </div>
            </div>

        </section>
    );
};

export default Skills;
