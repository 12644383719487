import React from "react";
import "./hero.css";


const Hero = () => {
    return (
        <section className="hero">

                <div className="hero-intro">
                    <p>Hi, I'm</p>
                    <h1>Bilal Asghar.</h1>
                    <h2>A <span>ReactJS</span> developer, nowadays mastering the <span>NodeJS</span>.</h2>
                    <div className="downloadCV-wrapper">
                        <a href="/">downloadCV</a>
                    </div>
                </div>

        </section>
    );
};

export default Hero;
