import React from "react";
import { Navbar, Hero, About, Portfolio, Contact } from "../src/components/Components";

const App = () => {
    return (
        <div>
            <Navbar />
            <Hero />
            <About />
            <Portfolio />
            <Contact />
           
            
        </div>
    );
};

export default App;
