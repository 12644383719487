import React from 'react';
import "./contact.css";
import icons from "../../assets/icons/Icons";

const Contact = () => {
   return (
      <section className="contact-section">
         <h1 className="contact-heading">contactMe</h1>
         <div className="contact-buttons">
            <a
               href="https://www.facebook.com/blalasghr/"
               target="_blank"
               rel="noreferrer"
            >
               <img src={icons.facebook} alt="" />
            </a>
            <a
               href="https://www.instagram.com/_blalasghr/"
               target="_blank"
               rel="noreferrer"
            >
               <img src={icons.instagram} alt="" />
            </a>
            <a
               href="https://www.linkedin.com/in/blalasghr/"
               target="_blank"
               rel="noreferrer"
            >
               <img src={icons.linkedin} alt="" />
            </a>
            <a
               href="https://github.com/blalasghr"
               target="_blank"
               rel="noreferrer"
            >
               <img src={icons.github} alt="" />
            </a>
         </div>
      </section>
   );
};

export default Contact;
